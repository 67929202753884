import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BannerLite.css';
import { PiX } from 'react-icons/pi';
import bannerimg from '../assets/banner/bannerimg.jpeg';

const BannerLite = ({theme}) => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/mentorship'); // Redirect to Home page
  };

  const handleDSAButtonClick = () => {
    navigate('/dsasheet'); // Redirect to DSA Essentials sheet page
  };

  return (
    <div className={`banner ${theme === 'dark' ? 'banner-dark' : 'banner-light'} `}>
      <div className="d-flex flex-wrap-reverse justify-content-between gap-2 container p-0">
      
        {/* banner heading and buttons  */}
        <div className="col-lg-6 col-md-6 col-8 banner-left mx-auto">
          <h1 className={`font-weight-bold banner-heading ${theme ==='dark' ? 'text-white' : 'text-black'} `}>Land Your Dream Job And Excel In Software Development</h1>
          
          {/* banner buttons */}
          <div className="d-flex flex-wrap gap-2 justify-content-md-start justify-content-center mx-auto pt-4">
            <button onClick={handleGetStarted} className={`${theme === 'dark' ? 'banner-button-dark' : 'banner-button'} our-mentors-btn`}>
                Our Mentors
            </button>
              
            <button onClick={handleDSAButtonClick} className={`${theme === 'dark' ? 'banner-button-dark' : 'banner-button'}  dsa-btn-home ml-md-3`}>
              DSA Essentials Sheet
            </button>
          </div>
    
        </div>

        {/* image portion */}
        <div className="col-lg-4 col-md-4 banner-right d-flex justify-content-right"></div> 
      </div>
    </div>

  );
};

export default BannerLite;
